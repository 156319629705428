import { css } from 'styled-components'
import { media } from './utils'

const type = {
    heading1: css`
        font-size: 49px;
		line-height: 58px;
		letter-spacing: -0.25;

        ${media.phone`
			font-size: 29px;
			line-height: 32px;
		`}
    `,
    heading2: css`
        font-size: 49px;
		line-height: 58px;
		letter-spacing: -0.25;

        ${media.phone`
			font-size: 29px;
			line-height: 32px;
		`}
    `,
    heading3: css`
        font-size: 45px;
		line-height: 54px;
		letter-spacing: -0.25;

        ${media.phone`
			font-size: 27px;
			line-height: 32px;
		`}
    `,
    heading4: css`
        font-size: 22px;
		line-height: 26px;
		letter-spacing: -0.25;
    `,
    body: css`
        font-size: 19px;
		line-height: 22px;
		letter-spacing: -0.25;

        ${media.phone`
			font-size: 17px;
			line-height: 22px;
		`}
    `,
}

export default type
