const colours = {
    black: '#000',
    offblack: '#062d32',
    white: '#fff',
    grey: '#e9e9e2',
    gold: '#aa9e54',
    yellow: '#fdf132',
    pink: '#c9a9b5',
    error: '#ff0000',
}

export default colours
