import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'

import globalType from './type'
import globalColours from './colours'

//const maxWidth = 656

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: ${globalColours.white};
        color: ${globalColours.black};
        font-family: 'Elementa', sans-serif;
    }

	::selection {
		background: ${globalColours.grey};
		color: ${globalColours.offblack};
	}

	/* width */
	/*::-webkit-scrollbar {
		width: 2px;
	}*/

	/* Track */
	/*::-webkit-scrollbar-track {
		background: none;
	}*/

	/* Handle */
	/*::-webkit-scrollbar-thumb {
		background: ${globalColours.offblack};
	}*/

	/* Handle on hover */
	/*::-webkit-scrollbar-thumb:hover {
		background: ${globalColours.offblack};
	}*/
    
    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        ${globalType.body};
        letter-spacing: 0.15px;
        font-weight: 300;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
    }

    p:first-child {
		margin-top: 0;
	}

    /* Next Wrapper */

    #__next {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
`

export const container = css`
    display: flex;
    width: 100%;
    /*max-width: ${maxWidth}px;*/
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    ${media.tablet`
		padding-left: 20px;
		padding-right: 20px;
	`}
`

export const noPadding = css`
    margin: 0 -40px;

    ${media.tablet`
		 margin: 0 -20px;
	`}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const button = css`
    max-width: 735px;
    min-width: 735px;
    padding: 38px;
    text-align: center;
    border: 1px solid black;
    cursor: pointer;
    text-transform: uppercase;

	&:visited {
		color: black;
	}

    ${media.phone`
		width: 100%;
		min-width: auto;
		padding: 27px;
		max-height: 100px;
		min-height: 100px;
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	`}

    &:hover {
        background: black;
        color: white;
    }
`

// Re-export colours & type

export const colours = globalColours
export const type = globalType
